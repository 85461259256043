new WOW().init();

const MENU_BTN = document.querySelector('.header__btn-menu')
const MENU = document.querySelector('.header__menu')
const TAB_ITEM = document.querySelectorAll('.tab-item')
const TAB_BTN = document.querySelector('.tab-btn')
const TAB_LIST = document.querySelector('.services__tabs')
const DESC_TITLE = document.querySelector('.services__desc-title')
const DESC_IMG = document.querySelector('.desc-img')
const DESC_TEXT = document.querySelector('.desc-text')
const PRODUCT_TAB = document.querySelectorAll('.text-block')
const SCROLL_BTN = document.querySelector('.top-scroll')
const WIDGET = document.querySelector('.widget')
const WIDGET_BTN = document.querySelector('.widget__btn')
const MODAL_BG = document.querySelector('.modal-bg')
const SERVICES_TITLE = document.querySelector('.modal-services__title')
const SERVICES_LIST = document.querySelector('.modal-services__list')
const MODAL_THANKS = document.querySelector('.modal-thanks')

const closeModalBtn = document.querySelectorAll('.closeModal')
const openModal = document.querySelectorAll('.openModal')
const modalItems = document.querySelectorAll('.modal')


//Открытие мобильного меню
MENU_BTN.addEventListener('click', () => {
    MENU.classList.toggle('active')
    MENU_BTN.classList.toggle('active')
})

$(window).resize(function () {
    if ($(this).width() > 1199) {
        MENU.classList.remove('active')
        MENU_BTN.classList.remove('active')
    }
})

//Остановка прокрутки сайта
function scrollStop() {
    document.querySelector("html").style.overflow = 'hidden'
}

//Восстановление прокрутки сайта
function scrollAuto() {
    document.querySelector("html").style.overflow = ''
}

// Открытие/закрытие модальных окон
function closeModal() {
    MODAL_BG.classList.remove('active')
    modalItems.forEach((item) => {
        item.classList.remove('active')
    })
    scrollAuto()
}

function openThanksModal() {
    closeModal()
    MODAL_BG.classList.add('active')
    MODAL_THANKS.classList.add('active')

}

closeModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
        closeModal()
    })
})

openModal.forEach((item) => {
    item.addEventListener('click', () => {
        modalItems.forEach((modals) => {
            if (modals.classList.contains(item.getAttribute('data-modal'))) {
                MODAL_BG.classList.add('active')
                modals.classList.add('active')
                scrollStop()
            }
        })
    })
})


$(document).ready(function () {
    $(".phone-mask").mask("9 (999) 999-99-99");
});

TAB_ITEM.forEach((item) => {

    item.addEventListener('click', () => {

        if (item.getAttribute('data-title') ||
            (item.getAttribute('data-img'))) {
            DESC_TITLE.innerText = item.getAttribute('data-title')
            DESC_IMG.src = item.getAttribute('data-img')
            DESC_TEXT.innerText = item.getAttribute('data-text')
            SERVICES_LIST.innerHTML = item.getAttribute('data-list')
            SERVICES_TITLE.innerText = item.getAttribute('data-title')
        }

        if (item.classList.contains('active')) {
            item.classList.add('active')
        } else {
            TAB_ITEM.forEach((item) => {
                item.classList.remove('active')
            })
            item.classList.add('active')
        }

        if (item.classList.contains('tab-item')) {
            TAB_LIST.classList.toggle('active')
            TAB_BTN.classList.toggle('active')
        }
    })
})
// Установка первого таба активным
TAB_ITEM[0].click()

// Установка возраста компании
const setYear = document.querySelector('.setYear')
const companyBirthDate = new Date(2005, 8, 16)
const today = new Date()
const companyOld = today.getFullYear() - companyBirthDate.getFullYear()
setYear.setAttribute('data-end', companyOld.toString())


// Бегущие цифры, запуск внизу где отслеживание скрола.
const counterNum = document.querySelectorAll('.counter-num')
const counter = document.querySelector('.counter')
const counterWrapper = document.querySelector('.counter__wrapper')
let isStartedCount = false

function counterPlus(count) {
    const endCount = +count.getAttribute('data-end')
    const delay = +count.getAttribute('data-time') / endCount
    const step = +count.getAttribute('data-step')
    let currentCount = +count.getAttribute('data-start')
    let endSymbol = ''
    if (count.getAttribute('data-symbol')) {
        endSymbol = count.getAttribute('data-symbol')
    }

    count.innerText = currentCount
    const timer = setInterval(() => {
        if (+endCount <= +currentCount) {
            clearInterval(timer)
            count.innerText = currentCount + endSymbol
        } else {
            currentCount = currentCount + step
            count.innerText = currentCount
        }
    }, delay)
}

// Фикс для fancybox
const certificateSliders = document.querySelector('.certificate__slider').querySelectorAll('.slider-img')
const fixFancybox = document.querySelector('.fixFancybox')

certificateSliders.forEach((item) => {
    let elementLi = document.createElement('li')
    elementLi.innerHTML = `<a href="${item.getAttribute('src')}" data-fancybox="gallery">
                              <img src="${item.getAttribute('src')}">
                           </a>`
    fixFancybox.append(elementLi)

})

window.addEventListener('click', (event) => {
    const element = event.target
    if (element.classList.contains('sliderImage')) {
        clickEventFancybox(element)
    }
})

const fancyboxItems = fixFancybox.querySelectorAll('a')

function clickEventFancybox(element) {
    fancyboxItems.forEach((item) => {
        if (item.getAttribute('href') === element.getAttribute('src')) {
            item.click()
        }
    })
}


//Инициализация слайдеров
function sliderInit() {

    const clients = new Swiper('.clients__slider', {
        loop: true,
        autoplay: {
            delay: 2000,
        },

        pagination: {
            el: '.clients-pagination',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 2,
                centeredSlides: false,
                grid: {
                    rows: 2,
                },
                spaceBetween: 15,
            },
            767: {
                slidesPerView: 4,
                centeredSlides: false,
                grid: false,
                spaceBetween: 25,
                loop: true,
                autoplay: {
                    delay: 2000,
                },
            },

            1199: {
                slidesPerView: 5,
                centeredSlides: true,
            },
        },
    });

    const projects = new Swiper('.projects__slider', {
        slidesPerView: 1,
        loop: true,
        speed: 500,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },

        pagination: {
            el: '.projects-pagination',
            type: "fraction",
        },

        navigation: {
            nextEl: '.projects-button-next',
            prevEl: '.projects-button-prev',
        },
    });


    const certificate = new Swiper('.certificate__slider-wrapper', {
        spaceBetween: 25,
        loop: true,
        pagination: {
            el: '.certificate-pagination',
            type: 'fraction',
        },

        navigation: {
            nextEl: '.certificate-button-next',
            prevEl: '.certificate-button-prev',
        },

        breakpoints: {
            320: {
                slidesPerView: 3,
                centeredSlides: true,
                spaceBetween: 15,
                slidesPerGroup: 1,
            },
            767: {
                slidesPerView: 3,
                spaceBetween: 25,
            },

            1199: {
                slidesPerView: 4,
            },
        },
    });

}

sliderInit()


//Раскрытие табов в блоке Реализованные проекты
PRODUCT_TAB.forEach((item) => {
    item.addEventListener('click', () => {
        if (item.classList.contains('active')) {
            item.classList.remove('active')
        } else {
            item.classList.add('active')
        }
    })
})

//Раскрытие виджета
WIDGET_BTN.addEventListener('click', () => {
    WIDGET.classList.toggle('active')
    WIDGET_BTN.classList.toggle('active')
})

// Функция появления кнопки прокрутки вверх
const FOOTER = document.querySelector('.footer')
let pageHeight = document.documentElement.scrollHeight
let footerHeight = FOOTER.clientHeight
let windowInnerHeight = window.innerHeight
let width = window.innerWidth

window.addEventListener('resize', () => {
    pageHeight = document.documentElement.scrollHeight
    footerHeight = FOOTER.offsetHeight
    windowInnerHeight = window.innerHeight
    width = window.innerWidth
})

let prev = 0


window.addEventListener('scroll', (event) => {

    let scrollPosition = pageHeight - footerHeight - 200
    let position = scrollY + windowInnerHeight

    if (scrollPosition <= position) {
        if (width <= 767) {
            SCROLL_BTN.style.bottom = '30px'
        }
        if (width < 1366) {
            SCROLL_BTN.style.bottom = '80px'
        }

    } else {
        SCROLL_BTN.style.bottom = '30px'
    }

    if (prev > scrollY && scrollY > 300) {
        SCROLL_BTN.classList.add('active')
        WIDGET.classList.remove('show')

    } else {
        SCROLL_BTN.classList.remove('active')
        WIDGET.classList.add('show')
    }

    prev = scrollY

    // Запуск счётчика
    if (scrollY >= counter.offsetTop - document.documentElement.clientHeight + 200 && !isStartedCount) {
        counterNum.forEach((item) => {
            counterPlus(item)
        })
        counterWrapper.classList.add('show-counter')
        isStartedCount = true
    }
})

//Прикрепить файл
$(document).ready(function () {
    $("#upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name").html(filename);
    });
});

$(document).ready(function () {
    $("#uploadModal").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name-modal").html(filename);
    });
});


$(function () {

    // const CATALOG_SEND = document.getElementById('catalog-send')

    $('form').submit(function (e) {
        e.preventDefault();
        let $form = $(this),
            formID;
        let formData = new FormData(this);
        $("form#analytics").serializeArray().forEach(function (field) {
            formData.append(field.name, field.value)
        });
        formID = "#" + $form.attr('id') + " button";  // Формируем переменную с номер формы и добавляем селектор button
        $(formID).prop('disabled', true);
        $(formID).css({'opacity': 0.3});

        if ($form.hasClass('catalogSend')) {
            $.ajax({
                url: './php/send-catalog.php',
                type: 'POST',
                contentType: false,
                processData: false,
                data: new FormData(this)
            })
            $.ajax({
                url: $form.attr('action'),
                type: $form.attr('method'),
                data: formData,
                cache: false,
                contentType: false,
                processData: false
            }).done(function () {
                $('form').trigger('reset');
                $(formID).prop('disabled', false);
                $(formID).css({'opacity': 1});
                openThanksModal()
            });

        } else {
            console.log('отправка Лида');
            $.ajax({
                url: $form.attr('action'),
                type: $form.attr('method'),
                data: formData,
                cache: false,
                contentType: false,
                processData: false
            }).done(function () {
                $('form').trigger('reset');
                $(formID).prop('disabled', false);
                $(formID).css({'opacity': 1});
                openThanksModal()
            });
        }

    });
});

$(document).ready(function () {
    let url_string = location.href;
    let url = new URL(url_string);
    let utm_source = url.searchParams.get('utm_source');
    let utm_medium = url.searchParams.get('utm_medium');
    let utm_campaign = url.searchParams.get('utm_campaign');
    let utm_content = url.searchParams.get('utm_content');
    let utm_term = url.searchParams.get('utm_term');
    let yclid = url.searchParams.get('yclid');
    let gclid = url.searchParams.get('gclid');
    let pm_position = url.searchParams.get('pm_position');
    let keyword = url.searchParams.get('keyword');
    let clientid;
    let googlecid;

    $('.analytics input[name="utm_source"]').val(utm_source);
    $('.analytics input[name="utm_medium"]').val(utm_medium);
    $('.analytics input[name="utm_campaign"]').val(utm_campaign);
    $('.analytics input[name="utm_content"]').val(utm_content);
    $('.analytics input[name="utm_term"]').val(utm_term);
    $('.analytics input[name="yclid"]').val(yclid);
    $('.analytics input[name="gclid"]').val(gclid);
    $('.analytics input[name="pm_position"]').val(pm_position);
    $('.analytics input[name="keyword"]').val(keyword);

    if (document.cookie.search('(?:^|;)\\s*_ga=([^;]*)') !== -1) {
        googlecid = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        $('.analytics input[name="googlecid"]').val(googlecid[0].slice(10));
    } else {
        googlecid = 'GA - отсуствует'
        $('.analytics input[name="googlecid"]').val(googlecid);
    }

    if (document.cookie.search('(?:^|;)\\s*_ym_uid=([^;]*)') !== -1) {
        clientid = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
        $('.analytics input[name="clientid"]').val(clientid[1]);
    } else {
        clientid = 'YM - отсуствует'
        $('.analytics input[name="clientid"]').val(clientid);
    }

});

